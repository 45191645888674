import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/global.css'
import 'element-plus/lib/theme-chalk/index.css';
import VueUeditorWrap from 'vue-ueditor-wrap';
import '../public/iconfont/iconfont.css'
import uploader from './components/common/uploader'

// import 'element-plus/packages/theme-chalk/src/base.scss'
import {
    ElAlert,
    ElAside,
    ElForm,
    ElFormItem,
    ElInput,
    ElInputNumber,
    ElButton,
    ElContainer,
    ElMain,
    ElHeader,
    ElMenu,
    ElMenuItem,
    ElMenuItemGroup,
    ElSubmenu,
    ElCard,
    ElCollapse,
    ElCollapseItem,
    ElTree,
    ElBreadcrumb,
    ElBreadcrumbItem,
    ElTabs,
    ElTabPane,
    ElTable,
    ElTableColumn,
    ElTag,
    ElSelect,
    ElOption,
    ElUpload,
    ElRadio,
    ElRadioGroup,
    ElDivider,
    ElDatePicker,
    ElCascader,
    locale,
    ElDialog,
    ElSwitch,
    ElEmpty,
    ElInfiniteScroll,
    ElLoading,
    ElMessage,
    ElMessageBox,
    ElNotification,
    ElBacktop,
    ElCheckbox,
    ElCheckboxGroup,
    ElCollapseTransition,
    ElPagination,
    ElDescriptions,
    ElDescriptionsItem,
    ElDropdownItem,
    ElDropdownMenu,
    ElDropdown,
} from 'element-plus'


import lang from 'element-plus/lib/locale/lang/zh-cn'
import 'dayjs/locale/zh-cn'
import axios from "axios";
import VueAxios from 'vue-axios'

// 图片
store.commit('setimgBaseUrl' , 'https://yzmp.nkyz.vip/images/')
// store.commit('setimgBaseUrl' , 'http://nktdec.ctvideo.cn/images/')



// 视频
store.commit('setvideoBaseUrl' , 'https://yzmp.nkyz.vip/videos/')
// store.commit('setvideoBaseUrl' , 'http://nktdec.ctvideo.cn/videos/')


// 视频上传  课程列表
// store.commit('setUploadUrl', 'http://nktdec.ctvideo.cn/uploader/chunkfile/')


// 上传文件链接
store.commit('setUploadUrl' , 'https://yzmp.nkyz.vip/nktdec/uploader/uploadfile')
// store.commit('setUploadUrl','http://nktdec.ctvideo.cn/nktdec/uploader/uploadfile')

// 富文本地址
store.commit('seteditorConfig', {
    // 访问 UEditor 静态资源的根路径，可参考 https://hc199421.gitee.io/vue-ueditor-wrap/#/faq
      UEDITOR_HOME_URL: '/UEditor/',
      // 服务端接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
    //   serverUrl: 'http://nktdec.ctvideo.cn/ueditor/jsp/controller.jsp',
      serverUrl: 'https://nktdec.nkyz.vip/ueditor/jsp/controller.jsp',
      initialFrameHeight: 500,
      // imageMaxSize: 102400000,
      // imageCompressBorder: 1600,
      autoHeightEnabled: false,
      catchRemoteImageEnable: false,
      // imageCompressEnable: false,
      // imageScaleEnabled: false
      })

locale(lang)
axios.defaults.baseURL = 'https://yzmp.nkyz.vip'
// axios.defaults.baseURL = 'http://82.156.212.242:55511/'
// axios.defaults.baseURL = 'http://nktdec.ctvideo.cn'
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(config => {
    // console.log(config)
    if(config.url.indexOf('uploader/chunkfile')===-1){
        config.headers.authorization = store.state.Token
    }
    return config;
}, err => {
    return Promise.reject(err);
});

const app = createApp(App)
app.use(VueAxios, axios)
app.use(uploader)
const components = [
    ElAlert,
    ElForm,
    ElFormItem,
    ElInput,
    ElInputNumber,
    ElButton,
    ElContainer,
    ElAside,
    ElMain,
    ElHeader,
    ElMenu,
    ElMenuItem,
    ElMenuItemGroup,
    ElSubmenu,
    ElCard,
    ElCollapse,
    ElCollapseItem,
    ElTree,
    ElBreadcrumb,
    ElBreadcrumbItem,
    ElTabs,
    ElTabPane,
    ElTable,
    ElTableColumn,
    ElTag,
    ElSelect,
    ElOption,
    ElUpload,
    ElRadio,
    ElRadioGroup,
    ElDivider,
    ElDatePicker,
    ElCascader,
    ElDialog,
    ElSwitch,
    ElEmpty,
    ElBacktop,
    ElCheckbox,
    ElCheckboxGroup,
    ElCollapseTransition,
    ElPagination,
    ElDescriptions,
    ElDescriptionsItem,
    ElDropdownItem,
    ElDropdownMenu,
    ElDropdown
]
const plugins = [
    ElInfiniteScroll,
    ElLoading,
    ElMessage,
    ElMessageBox,
    ElNotification,
]
components.forEach(component => {
    app.component(component.name, component)
})
plugins.forEach(plugin => {
    app.use(plugin)
})
app.use(VueUeditorWrap)
app.use(store).use(router).mount('#app')
